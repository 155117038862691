import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";

import { PaddingContainer } from "../components/Containers";
import { RegisterForm } from "../components/Register";
import { H1 } from "../components/Text";

const EnglishRegisterPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Register" lang="en" pathName={location.pathname} />
    <PaddingContainer>
      <H1>Register</H1>
      <RegisterForm language="en" />
    </PaddingContainer>
  </>
);

export default EnglishRegisterPage;
